*, :before, :after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  border-color: theme("borderColor.DEFAULT", currentColor);
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.5;
  font-family: theme("fontFamily.sans", ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-feature-settings: theme("fontFamily.sans[1].fontFeatureSettings", normal);
  font-variation-settings: theme("fontFamily.sans[1].fontVariationSettings", normal);
  -webkit-tap-highlight-color: transparent;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: theme("fontFamily.mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
  font-feature-settings: theme("fontFamily.mono[1].fontFeatureSettings", normal);
  font-variation-settings: theme("fontFamily.mono[1].fontVariationSettings", normal);
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: theme("colors.gray.400", #9ca3af);
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --background-color: #f9f9f9;
  --accent-background-color: #f2f2f2;
  --foreground-color: #171815;
  --brand-color: #ff5200;
  --md: 720px;
  --lg: 992px;
  --inner-gap: 1rem;
  --outer-gap: 2rem;
  --scale: 400;
}

@media only screen and (width >= 720px) {
  :root {
    --scale: 1200;
  }
}

@media only screen and (width >= 992px) {
  :root {
    --inner-gap: 2rem;
    --outer-gap: 4rem;
    --scale: 1600;
  }
}

* {
  box-sizing: border-box;
}

html {
  color: var(--foreground-color);
  background: var(--background-color);
  font-optical-sizing: auto;
  letter-spacing: .02em;
  font-family: Inter Tight, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  font-size: calc(16 * (100vw / var(--scale)));
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  margin-bottom: .5rem;
  font-size: 1.24rem;
  font-weight: 700;
}

p {
  word-break: break-word;
}

p:not(:first-child) {
  margin-top: .5rem;
}

.underline {
  text-shadow: 2px 2px var(--background-color), 2px -2px var(--background-color), -2px 2px var(--background-color), -2px -2px var(--background-color), 1px 1px var(--background-color), 1px -1px var(--background-color), -1px 1px var(--background-color), -1px -1px var(--background-color);
  border-bottom: .0666667rem solid;
  line-height: .95;
  display: inline-block;
}

address {
  font-style: normal;
}

.text-brand {
  color: var(--brand-color);
}

section {
  gap: var(--inner-gap);
  padding: var(--outer-gap);
  flex-wrap: wrap;
  display: flex;
}

.span-sm-1, .span-md-1, .span-lg-1 {
  flex: 0 0 calc(33.33% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

.span-sm-2, .span-md-2, .span-lg-2 {
  flex: 0 0 calc(50% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

.span-sm-3, .span-md-3, .span-lg-3 {
  flex: 0 0 calc(100% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

@media (width >= 720px) {
  .span-md-1 {
    flex: 0 0 calc(33.33% - var(--inner-gap));
  }

  .span-md-2 {
    flex: 0 0 calc(66.66% - var(--inner-gap));
  }

  .span-md-3 {
    flex: 0 0 calc(100% - var(--inner-gap));
  }
}

@media (width >= 992px) {
  .span-lg-1 {
    flex: 0 0 calc(33.33% - var(--inner-gap));
  }

  .span-lg-2 {
    flex: 0 0 calc(66.66% - var(--inner-gap));
  }

  .span-lg-3 {
    flex: 0 0 calc(100% - var(--inner-gap));
  }
}

.no-gap-bottom {
  margin-bottom: calc(-1 * var(--inner-gap));
}

header {
  padding: var(--outer-gap);
}

.logo {
  display: inline-block;
}

.logo svg {
  width: auto;
  height: 2.5rem;
}

.slogan {
  margin-top: .5rem;
}

#intro {
  aspect-ratio: 2;
  background-color: var(--accent-background-color);
  width: 100%;
}

.team-photos {
  gap: var(--inner-gap);
  aspect-ratio: 1;
  filter: saturate(0);
  grid-template-rows: 2fr 1.5fr;
  grid-template-columns: 1fr 1fr;
  height: auto;
  display: grid;
}

.team {
  grid-area: 1 / 1 / 2 / 4;
}

.top {
  grid-area: 2 / 1 / 3 / 3;
}

.bottom {
  grid-area: 2 / 3 / 3 / 4;
}

@media only screen and (width >= 720px) {
  .team-photos {
    aspect-ratio: 2;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
  }

  .team {
    grid-area: 1 / 1 / 3 / 3;
  }

  .top {
    grid-area: 1 / 3 / 2 / 4;
  }

  .bottom {
    grid-area: 2 / 3 / 3 / 4;
  }
}

.team-photos > figure {
  object-fit: contain;
  margin: 0;
  overflow: hidden;
}

.team-photos > figure > picture > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.customer {
  background: var(--accent-background-color);
  padding: calc(2 * var(--inner-gap));
  justify-content: center;
  align-items: center;
  display: flex;
}

.customer--logo {
  width: 100%;
  max-height: 7rem;
}

.team-members {
  gap: var(--inner-gap);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.team-members picture img {
  aspect-ratio: 4 / 5;
}

@media only screen and (width >= 992px) {
  .team-members {
    grid-template-columns: repeat(3, 1fr);
  }
}

.member-name {
  margin-top: .5rem;
  font-weight: 700;
}

.country {
  margin: var(--inner-gap) 0;
}

.locations {
  gap: var(--inner-gap);
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.location--name {
  font-weight: 700;
}

picture img {
  object-fit: cover;
  width: 100%;
}

.picture-2-1 img {
  aspect-ratio: 2;
}

footer {
  padding: var(--outer-gap);
  gap: .5em;
  display: flex;
}

.saturate-0 {
  filter: saturate(0);
}

.inline-icon {
  gap: .2em;
  display: inline;
}

.inline-icon .icon {
  margin-top: -.1em;
}

.icon {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}
/*# sourceMappingURL=index.7c888fe7.css.map */
