@import "preflight.pcss";

:root {
  /* Colors */
  --background-color: #f9f9f9;
  --accent-background-color: #f2f2f2;
  --foreground-color: #171815;
  --brand-color: #ff5200;
  /* Breakpoints */
  --md: 720px;
  --lg: 992px;
  /* Layout */
  --inner-gap: 1rem;
  --outer-gap: 2rem;
  --scale: 400;
}

@media only screen and (min-width: 720px) {
  :root {
    --scale: 1200;
  }
}

@media only screen and (min-width: 992px) {
  :root {
    --inner-gap: 2rem;
    --outer-gap: 4rem;
    --scale: 1600;
  }
}

* {
  box-sizing: border-box;
}

html {
  /* Colors */
  color: var(--foreground-color);
  background: var(--background-color);

  /* Typography */
  font-family: "Inter Tight", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.02em;
  font-size: calc(16 * (100vw / var(--scale)));
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Typography */
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  font-size: 1.24rem;
  margin-bottom: 0.5rem;
}

p {
  word-break: break-word;
}

p:not(:first-child) {
  margin-top: 0.5rem;
}

.underline {
  border-bottom: calc(1rem / 15) solid currentColor;
  display: inline-block;
  line-height: 0.95;
  text-shadow: 2px 2px var(--background-color), 2px -2px var(--background-color),
    -2px 2px var(--background-color), -2px -2px var(--background-color),
    1px 1px var(--background-color), 1px -1px var(--background-color),
    -1px 1px var(--background-color), -1px -1px var(--background-color);
}

address {
  font-style: normal;
}

.text-brand {
  color: var(--brand-color);
}

/* Layout */
section {
  gap: var(--inner-gap);
  padding: var(--outer-gap);
  display: flex;
  flex-wrap: wrap;
}

.span-sm-1,
.span-md-1,
.span-lg-1 {
  flex: 0 0 calc(33.33% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

.span-sm-2,
.span-md-2,
.span-lg-2 {
  flex: 0 0 calc(50% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

.span-sm-3,
.span-md-3,
.span-lg-3 {
  flex: 0 0 calc(100% - var(--inner-gap));
  margin-bottom: var(--inner-gap);
}

@media (min-width: 720px) {
  .span-md-1 {
    flex: 0 0 calc(33.33% - var(--inner-gap));
  }

  .span-md-2 {
    flex: 0 0 calc(66.66% - var(--inner-gap));
  }

  .span-md-3 {
    flex: 0 0 calc(100% - var(--inner-gap));
  }
}

@media (min-width: 992px) {
  .span-lg-1 {
    flex: 0 0 calc(33.33% - var(--inner-gap));
  }

  .span-lg-2 {
    flex: 0 0 calc(66.66% - var(--inner-gap));
  }

  .span-lg-3 {
    flex: 0 0 calc(100% - var(--inner-gap));
  }
}

.no-gap-bottom {
  margin-bottom: calc(-1 * var(--inner-gap));
}

/* Header */
header {
  padding: var(--outer-gap);
}

.logo {
  display: inline-block;
}

.logo svg {
  height: 2.5rem;
  width: auto;
}

.slogan {
  margin-top: 0.5rem;
}

/* Intro */
#intro {
  width: 100%;
  aspect-ratio: 2 / 1;
  background-color: var(--accent-background-color);
}

/* Team photos */
.team-photos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1.5fr;
  gap: var(--inner-gap);
  aspect-ratio: 1;
  height: auto;
  filter: saturate(0);
}

.team {
  grid-area: 1 / 1 / 2 / 4;;
}

.top {
  grid-area: 2 / 1 / 3 / 3;;
}

.bottom {
  grid-area: 2 / 3 / 3 / 4;
}

@media only screen and (min-width: 720px) {
  .team-photos {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: row;
    aspect-ratio: 2 / 1;
  }

  .team {
    grid-area: 1 / 1 / 3 / 3;
  }
  
  .top {
    grid-area: 1 / 3 / 2 / 4;
  }
  
  .bottom {
    grid-area: 2 / 3 / 3 / 4;
  }
}

.team-photos > figure {
  overflow: hidden;
  object-fit: contain;
  margin: 0;
}

.team-photos > figure > picture > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Customers */
.customer {
  background: var(--accent-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(2 * var(--inner-gap));

  &--logo {
    width: 100%;
    max-height: 7rem;
  }
}

/* Team members */
.team-members {
  display: grid;
  gap: var(--inner-gap);
  grid-template-columns: repeat(2, 1fr);
  picture img {
    aspect-ratio: 4 / 5;
  }
}

@media only screen and (min-width: 992px) {
  .team-members {
    grid-template-columns: repeat(3, 1fr);
  }
}

.member-name {
  margin-top: 0.5rem;
  font-weight: 700;
}

/* Locations */
.country {
  margin: var(--inner-gap) 0;
}

.locations {
  display: grid;
  gap: var(--inner-gap);
  grid-template-columns: repeat(2, 1fr);
}

.location {
  &--name {
    font-weight: 700;
  }
}

picture img {
  width: 100%;
  object-fit: cover;
}

.picture-2-1 img {
  aspect-ratio: 2 / 1;
}

/* Footer */
footer {
  display: flex;
  gap: 0.5em;
  padding: var(--outer-gap);
}

.saturate-0 {
  filter: saturate(0);
}

/* Icon */
.inline-icon {
  display: inline;
  gap: 0.2em;
  .icon {
    margin-top: -0.1em;
  }
}


.icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  fill: currentColor;
}